.darken {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  background-attachment: fixed;
  min-height: 100vh;
}

.back {
  z-index: -1;
}

.full {
  position: fixed;
  height: 100vh;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.outer {
  display: table;
  position: inherit;
  top: 0;
  left: 0;
  height: 90vh;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  /* Whatever width you want */
}

::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: #1d1e22;
}

::-webkit-scrollbar-thumb {
  border-radius: 1em;
  background-color: #b3b3b3;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #b31c47;
}
